<template>
  <div class="container">
    <RegisterForm
      v-if="!isInvite"
      :parents_url="'/public/registration'"
      :isPublic="true"
    ></RegisterForm>
    <RegisterForm
      v-if="isInvite"
      :parents_url="'/public/registration'"
      :isInvite="true"
      :usingPublic="true"
    ></RegisterForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RegisterForm from "@/components/SmartVMS/RegisterForm";

export default {
  data: function () {
    return {
      isInvite: false
    };
  },
  components: {
    RegisterForm
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser"
    })
  },
  mounted: function () {
    if (this.$route.params.isInvite) {
      this.getInviteDetails();
    }
  },
  methods: {
    getInviteDetails() {
      this.isInvite = this.$route.params.isInvite;
    }
  }
};
</script>

<style lang="scss"></style>
